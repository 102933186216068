import React from "react";
import Layout from "../../components/layout";
import { article } from "../../styles/modules/post_template.module.css";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";
import { ZodiacSign } from "../../components/ZodiacSign";

const formatDate = (dateISOString, region, options) => {
  const date = new Date(dateISOString);

  return date.toLocaleDateString(region, options);
};

function Page() {
  const date = `${new Date()}`;

  const textDate = formatDate(date, "en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const data = {
    week: "01",
    zodiacSigns: [
      {
        signName: "aries",
        spanishName: "Aries",
        colorName: "Emerald Green",
        colorHex: "#ccddaa",
        description:
          "Today, emerald green is the lucky choice for Aries, as it symbolizes renewal and positive energy. This vibrant shade will help you face challenges with determination and attract good luck in your endeavors.",
      },
      {
        signName: "taurus",
        spanishName: "Taurus",
        colorName: "Sapphire Blue",
        colorHex: "#336699",
        description:
          "For Taurus, sapphire blue is today's lucky color. It represents stability and confidence, providing you with an aura of tranquility that will allow you to make wise decisions and attract prosperity in your projects.",
      },
      {
        signName: "gemini",
        spanishName: "Gemini",
        colorName: "Bright Yellow",
        colorHex: "#ffcc00",
        description:
          "Geminis will find fortune in bright yellow today. This color symbolizes creativity and communication, providing you with the necessary energy to express your ideas clearly and effectively, which will lead to success.",
      },
      {
        signName: "cancer",
        spanishName: "Cancer",
        colorName: "Shiny Silver",
        colorHex: "#c0c0c0",
        description:
          "Today, shiny silver is the right choice for Cancer. It represents intuition and protection, providing you with the necessary emotional tranquility. This silver shade will act as a positive shield, attracting good vibrations around you.",
      },
      {
        signName: "leo",
        spanishName: "Leo",
        colorName: "Warm Orange",
        colorHex: "#ff9900",
        description:
          "Leo finds their lucky color in warm orange today. This shade represents vitality and self-confidence, driving you to stand out and shine in any situation. Take advantage of this energy to achieve your goals successfully.",
      },
      {
        signName: "virgo",
        spanishName: "Virgo",
        colorName: "Earth Brown",
        colorHex: "#8b4513",
        description:
          "For Virgo, earth brown is today's lucky color. It symbolizes stability and connection with nature, providing you with a solid foundation to face any challenge. With this color, you'll find balance and harmony in your actions.",
      },
      {
        signName: "libra",
        spanishName: "Libra",
        colorName: "Soft Pink",
        colorHex: "#ffcccc",
        description:
          "Libra, today your lucky color is soft pink. This shade represents love and harmony, creating a conducive environment for positive relationships. Use this color to strengthen emotional bonds and attract peace to your life.",
      },
      {
        signName: "scorpio",
        spanishName: "Scorpio",
        colorName: "Intense Red",
        colorHex: "#ff0000",
        description:
          "Scorpios will find their luck in intense red today. This color represents passion and determination, driving you to face challenges with bravery. Use this shade to attract the necessary energy and achieve your objectives successfully.",
      },
      {
        signName: "sagittarius",
        spanishName: "Sagittarius",
        colorName: "Royal Purple",
        colorHex: "#800080",
        description:
          "Sagittarius, today royal purple is your lucky color. It symbolizes wisdom and expansion, providing you with the necessary inspiration to explore new possibilities. Use this shade to expand your horizons and pursue your dreams with confidence.",
      },
      {
        signName: "capricorn",
        spanishName: "Capricorn",
        colorName: "Light Gray",
        colorHex: "#d3d3d3",
        description:
          "For Capricorn, light gray is today's lucky color. It represents stability and practicality, providing you with a solid foundation for making sound decisions. Use this shade to approach your responsibilities with determination.",
      },
      {
        signName: "aquarius",
        spanishName: "Aquarius",
        colorName: "Refreshing Turquoise",
        colorHex: "#00ced1",
        description:
          "Aquarius, find your luck in refreshing turquoise today. This shade symbolizes innovation and freedom, providing you with the necessary inspiration to follow your creative instincts. Use this color to break barriers and experience success in new ventures.",
      },
      {
        signName: "pisces",
        spanishName: "Pisces",
        colorName: "Tranquil Lavender",
        colorHex: "#e6e6fa",
        description:
          "Pisces, today tranquil lavender is your lucky color. It represents peace and intuition, creating a serene environment around you. Use this shade to connect with your intuition and make decisions that guide you towards harmony and well-being.",
      },
    ],
  };

  return (
    <Layout locale="en">
      <article className={article}>
        <h1>Lucky Color for Today {textDate}</h1>
        <p>
          Welcome to the <strong>lucky color horoscope for today!</strong>{" "}
          Discover how vibrant hues can positively influence your day based on
          your zodiac sign. Join us as we explore the lucky colors that will
          surround you and propel you towards prosperity over the course of this
          week.
        </p>
        <h2>Lucky Colors According to Your Sign:</h2>

        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "1rem",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
            gap: "1rem",
          }}
        >
          {data.zodiacSigns.map(sign => (
            <li>
              <article
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "140px 1fr",
                  boxShadow: "0 0 3px 0 gray",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: sign.colorHex,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "1rem",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  <ZodiacSign
                    sign={sign.signName}
                    color={darkOrLightColor(sign.colorHex)}
                    width={44}
                    height={44}
                  />
                  <span
                    style={{
                      fontSize: "1.2rem",
                      color: darkOrLightColor(sign.colorHex),
                    }}
                  >
                    {sign.colorHex}
                  </span>
                </div>
                <div style={{ padding: ".8rem" }}>
                  <h3
                    style={{
                      fontSize: "1.4rem",
                      margin: "0 0",
                      padding: "0.5rem 0",
                      lineHeight: 1.3,
                    }}
                  >
                    The color for {sign.signName} today is {sign.colorName}
                  </h3>
                  <p
                    style={{
                      fontSize: ".9rem",
                      margin: ".5rem 0",
                      lineHeight: 1.5,
                    }}
                  >
                    {sign.description}
                  </p>
                </div>
              </article>
            </li>
          ))}
        </ul>
        <p>
          Make the most of the power of colors and let them guide your journey
          towards harmony and success. Remember to carry with you the positive
          energy that each shade brings to your zodiac sign. May these lucky
          colors brighten your day and bring you the fortune you seek! Until the
          next installment of astral colors! 🌈✨
        </p>
      </article>
    </Layout>
  );
}

export const Head = () => {
  const date = `${new Date()}`;

  const textDate = formatDate(date, "en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <title>Lucky Colors of the Week 《Zodiac Sign》</title>
      <meta
        name="description"
        content={`Explore today's color horoscope ${textDate} and discover how your zodiac sign can shine with lucky shades`}
      />
    </>
  );
};

export default Page;
